<template>
  <div id="container-options-menu">
    <principalMenuItem v-for="item in items"
      :key="item.title"
      :title="$t(item.title)"
      :description="$t(item.description)"
      :route="item.route"
      :img="item.img"
      :permission="item.permission.indexOf(userRole) >= 0"
    ></principalMenuItem>
  </div>
</template>
<script>
import principalMenuItem from '@core/spore-components/principalMenuItem/principalMenuItem.vue'

export default {
  components: {
    principalMenuItem
  },
  data () {
    return {
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      items: [
        {
          route: '/dashboard/GWH0079/general',
          img: 'dashboard-blue',
          title: 'General',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        },
        {
          route: '/dashboard/GWH0079/deployment',
          img: 'dashboard-desploy-blue',
          title: 'Despliegue',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Menu.scss";
</style>
